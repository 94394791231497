<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Properties</h1>
        </v-col>

        <v-col class="pl-12">
          <v-text-field
            label="Search Properties"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.propertyDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Property</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Properties</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="grid-row">
            <v-data-table
              :headers="tableHeaders"
              :items="properties"
              no-data-text="No properties"
              :items-per-page="-1"
            >
              <template v-slot:item.rent="{ item }">
                £{{ formatPrice(item.rent) }}
              </template>

              <template v-slot:item.property_name="{ item }">
                {{ item.property_name }}, {{ item.postcode }}
              </template>

              <template v-slot:item.open_to_applications="{ item }">
                <div v-if="item.open_to_applications">
                  <v-icon color="green" class="mr-2">mdi-check-circle </v-icon>

                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    :href="getFrontEndUrl(item.sme_id)"
                    target="_blank"
                  >
                    <v-icon small>mdi-monitor-dashboard</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-icon color="red">mdi-close-circle </v-icon>
                </div>
              </template>

              <template v-slot:item.closing_date="{ item }">
                <div v-if="item.closing_date">
                  {{ item.formatted_dates.close }}
                </div>
                <div v-else>N/A</div>
              </template>

              <template v-slot:item.available_from="{ item }">
                <div v-if="item.available_from">
                  {{ formatDate(item.available_from) }}
                </div>
                <div v-else>N/A</div>
              </template>

              <template v-slot:item.viewing="{ item }">
                <div v-if="item.viewing_date">
                  {{ formatDate(item.viewing_date) }}
                </div>
                <div v-if="item.viewing_time">
                  {{ item.viewing_time }}
                </div>
              </template>

              <template v-slot:item.url_360="{ item }">
                <div v-if="item.url_360">
                  <v-icon color="green">mdi-check-circle </v-icon>
                </div>
                <div v-else>
                  <v-icon color="red">mdi-close-circle </v-icon>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="green lighten-4 green--text"
                  :to="{
                    name: 'module-sbpm-properties-individual',
                    params: { propertyId: item.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.propertySmallDialog.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item)"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <PropertySmallDialog ref="propertySmallDialog" />
    <v-dialog v-model="deleteProperty.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Property</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProperty.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PropertySmallDialog from "./components/PropertySmallDialog.vue";

export default {
  components: {
    PropertySmallDialog,
  },

  data() {
    return {
      deleteProperty: {
        dialog: false,
        property: {},
        loading: false,
      },

      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "Address", value: "property_name" },
        { text: "Rent", value: "rent" },
        { text: "Open to Applications", value: "open_to_applications" },
        { text: "Viewing", value: "viewing" },
        { text: "From", value: "available_from" },
        { text: "Close", value: "closing_date" },
        { text: "360", value: "url_360" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    properties() {
      let properties = this.$store.getters["sbpm/propertiesStore/all"];

      properties = properties.filter(
        (c) => c.status == "Applications in Progress" || c.status == "Marketing"
      );

      if (this.searchTerm !== "") {
        properties = properties.filter((p) => {
          const short = p.property_name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return short.includes(searchTerm);
        });
      }

      return properties;
    },
  },

  methods: {
    getFrontEndUrl(unique_id) {
      return (
        process.env.VUE_APP_SBPM_URL + "/property-application/" + unique_id
      );
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    openDelete(property) {
      this.deleteProperty.property = property;
      this.deleteProperty.dialog = true;
    },

    resetDelete() {
      this.deleteProperty.dialog = false;
      this.deleteProperty.property = {};
      this.deleteProperty.loading = false;
    },

    saveDelete() {
      this.deleteProperty.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteProperty", {
          appId: this.$route.params.id,
          propertyId: this.deleteProperty.property.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProperty.loading = false;
        });
    },
  },
};
</script>
